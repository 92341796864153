import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useImageComponent, useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';
import { useTranslation } from '../utils/translation';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}

export interface PostItemProps {
  image: { imageId: string; alt: string };
  title: string;
  date: string;
  link: LinkProps;
  commentCount?: number;
  tag?: string;
  authors?: AuthorProp[];
  sponsoredItem?: boolean;
}

const Wrapper = styled.article`
  display: flex;
  margin-bottom: 10px;
`;

const Post = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-decoration: none;
  margin: 0 0 var(--spacer-m-to-s);

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      flex-direction: row;
    `,
  )}
`;

const LeftSide = styled.div`
  position: relative;
  width: 100%;

  picture {
    width: 100%;
    height: 100%;
    max-height: 300px;
  }

  img {
    object-fit: cover;
  }

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      width: auto;

      picture {
        width: 240px;
      }

      img {
        object-fit: initial;
      }
    `,
  )}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacer-s);
  justify-content: space-between;
  margin: var(--spacer-s) 0 0 0;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      margin: 0 0 0 var(--spacer-l);
    `,
  )}
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.foreground};
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.38;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 26px;
    `,
  )}
`;

const Informations = styled.div`
  align-items: center;
  color: ${(props) => (props.theme.isDark ? 'white' : props.theme.palette.blackAlt)};
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  font-size: 12px;
  gap: var(--spacer-xs);
  justify-content: flex-start;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 14px;
    `,
  )}
`;

const PublishDate = styled.span``;

const AuthorLabel = styled.span``;

const Comments = styled.div``;

const Sponsored = styled.span``;

export const PostItemLine: any = (props: PostItemProps) => {
  const { image, title, date, link, authors, commentCount, sponsoredItem } = props;

  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();
  const t = useTranslation();

  const imageProps = {
    ...image,
    mobileWidth: 280,
    mobileHeight: 157,
    desktopWidth: 240,
    desktopHeight: 135,
  };

  const authorString = authors && authors.length ? authors.map((author): string => author.title).join(', ') : null;

  return (
    <LinkComponent {...link}>
      <Wrapper>
        <Post>
          <LeftSide>
            <ImageComponent {...imageProps} />
          </LeftSide>
          <Content>
            <Title data-testid="meta-label">{title}</Title>
            <Informations>
              <PublishDate data-testid="meta-date">{date}</PublishDate>
              {authorString && (
                <AuthorLabel>{t('design.postItemLine.authorLabel', { author: authorString })}</AuthorLabel>
              )}
              {commentCount > 0 && (
                <Comments>
                  ({commentCount} {t('design.postItemLine.commentLabel')}
                  {commentCount > 1 ? 's' : ''}
                  {sponsoredItem && ' - sponsorisé'})
                </Comments>
              )}
              {sponsoredItem && !commentCount && <Sponsored>(sponsorisé)</Sponsored>}
            </Informations>
          </Content>
        </Post>
      </Wrapper>
    </LinkComponent>
  );
};
