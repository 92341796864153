import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TertiaryHero } from '@marty-js/design/src/molecules/tertiary-hero';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';

import { Newsletter } from '@marty-js/design/src/molecules/newsletter';
import { ItemCtaSimple } from '@marty-js/design/src/molecules/item-cta-simple';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { GenericItem } from '@marty-js/api-sdk/types';
import { ShowcaseItem } from '@marty-js/design/src/molecules/showcase-item';
import { PostItemLine } from '@marty-js/design/src/molecules/post-item-line';
import { useGetGenericItemBySection } from '@marty-js/api-sdk/services/genericItemBySection';
import { GetGenericItemBySectionVariables } from '@marty-js/api-sdk/__generated__/GetGenericItemBySection';
import { getImageUrl } from '@marty-js/design/src/utils/image.service';
import { SectionInsertBloc } from '@marty-js/design/src/molecules/section-insert';
import { displayDateDiff } from '@marty-js/design/src/atoms/date/utils';
import { Tag } from '@marty-js/design/src/atoms/tag';
import {
  LayoutSectionProps,
  PluginArticleProcessorLight,
  PluginGenericItemByQueryConfig,
  PluginSectionInserts,
  SectionInsertItem,
} from '../types';
import { Container, MainContent, SideContent, SideContentSpacer } from '../../../atoms/grid-container';
import { Ad } from '../../../ads/ad';
import { PostsList } from '../../../layout/post/posts-list';
import { useInfiniteScroll } from '../../../utils/infinite-scroll.hooks';
import { useSdkConfig } from '../../../utils/config';
import { useSetMetaTitleIfNull, useSetPageNumber } from '../../../utils/metaSeoContext';
import { NavLink } from '../../../atoms/nav-link';
import { LinkComponentHref } from '../../../types';

const Trends = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  grid-column: 1 / 10;
  margin: var(--spacer-s) 0;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      justify-content: flex-start;
      width: calc(100vw - var(--spacer-s));
      overflow-x: auto;
      white-space: nowrap;
    `,
  )}
`;

const Heros = styled.div`
  display: flex;
  gap: var(--spacer-fluid);
  margin: var(--spacer-s-fluid) 0 var(--spacer-m-fluid);
  grid-column: 1 / 10;

  ${mq.lte(
    1200,
    css`
      width: calc(100vw - var(--spacer-s));
      overflow-x: auto;
    `,
  )}
`;

const ListItemRow = styled.ul`
  display: flex;
  flex-direction: column;
`;

const DesktopOnly = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const SideContentBottom = styled.div`
  display: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
      position: sticky;
      top: 10px;
      margin-top: var(--spacer-m);
    `,
  )}
`;

const Title = styled.span`
  color: ${(props) => (props.theme.isDark ? props.theme.palette.white : props.theme.palette.black)};
  display: block;
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 18px;
  font-weight: bold;
  margin: var(--spacer-fluid) 0;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 26px;
    `,
  )}
`;

export type HomePageClubicData = {
  children: {
    PLUGIN_NAME: string;
    config: { type: string; objectKey: string };
    sectionList: { id: string; title: string; url: string }[];
    templateSectionConfig: unknown;
  };
  hero: {
    heros: {
      id: string;
      url: string;
      image: string;
      title: string;
      updatedAt?: string;
      comments?: number;
      tagSlugs?: string[];
    }[];
  };
  lastDeals: { genericItemList: SectionInsertItem[] };
  lastProductReviews: { genericItemList: SectionInsertItem[] };
  lastServiceReviews: { genericItemList: SectionInsertItem[] };
  lastBestPicks: { genericItemList: SectionInsertItem[] };
  mainFlux: PluginGenericItemByQueryConfig;
  textJson: PluginArticleProcessorLight;
  inserts?: PluginSectionInserts;
  topDownload: any;
};

const formatInsertElements = (data: PluginSectionInserts, imageApiHostname: string): any[] => {
  const insertElements: any[] = [];

  if (!data?.inserts || !data?.inserts.length) {
    return insertElements;
  }

  data.inserts.filter(Boolean).forEach((insert, index): void => {
    const backgroundImageUrl = getImageUrl({
      hostname: imageApiHostname,
      imageId: insert?.backgroundImage || '0',
      width: 640,
      height: 480,
      title: insert.title,
    });

    insertElements.push(
      <SectionInsertBloc
        key={index}
        title={insert.title}
        link={insert.link}
        linkLabel={insert.linkLabel}
        backgroundImageUrl={backgroundImageUrl || null}
        itemList={insert.itemList.map((sectionItem) => {
          return sectionItem;
        })}
      />,
    );
  });

  return insertElements;
};

const HomePageClubic: React.FC<LayoutSectionProps<HomePageClubicData>> = ({ section, data, pageInfo }) => {
  const t = useTranslation();
  const sdkConfig = useSdkConfig();
  const [genericItems, setGenericItems] = useState<GenericItem[]>(data.mainFlux?.genericItemList);
  const size = data.mainFlux?.info?.size ?? 10;
  const refSideBar = useRef<HTMLElement>();

  const variables: GetGenericItemBySectionVariables = useMemo(
    () => ({
      sectionId: pageInfo?.section?.id,
      from: 0,
      size,
    }),
    [pageInfo?.section?.id, size],
  );

  const { fetchMoreData, fetchData, result, loading, called } = useGetGenericItemBySection({ variables });

  const detectorRef = useInfiniteScroll(() => {
    const from = genericItems.length;
    if (called) {
      fetchMoreData({ from });
    } else if (!loading) {
      fetchData({ variables: { ...variables, from } });
    }
  });

  useEffect(() => {
    if (!loading) {
      if (result) {
        setGenericItems([...data.mainFlux.genericItemList, ...result.genericItemBySection.genericItems]);
      }
    }
  }, [setGenericItems, result, loading, data.mainFlux, pageInfo.section.url]);

  const additionalItems: PluginSectionInserts = {
    PLUGIN_NAME: 'SectionInsert',
    inserts: [
      {
        itemList: data.lastDeals?.genericItemList ?? [],
        title: 'Les derniers bons plans',
        link: '/bons-plans/',
        linkLabel: 'Voir plus de bons plans',
        backgroundImage: 'string',
      },
      {
        itemList: data.lastProductReviews?.genericItemList ?? [],
        title: 'Nos derniers tests produits',
        link: '/test-produit/',
        linkLabel: 'Voir plus de tests',
        backgroundImage: data.lastProductReviews?.genericItemList[0].imageId,
      },
      {
        itemList: data.lastServiceReviews?.genericItemList ?? [],
        title: 'Nos derniers avis services et logiciels',
        link: '/telecharger/actus-logiciels/avis',
        linkLabel: "Voir plus d'avis et logiciels",
        backgroundImage: data.lastServiceReviews?.genericItemList[0].imageId,
      },
      {
        itemList: data.lastBestPicks?.genericItemList ?? [],
        title: 'Nos derniers comparatifs',
        link: '/guide-achat/',
        linkLabel: 'Voir plus de comparatifs',
        backgroundImage: data.lastBestPicks?.genericItemList[0].imageId,
      },
    ],
  };

  const insertElements = formatInsertElements(additionalItems, sdkConfig.imageApiHostname);

  useSetMetaTitleIfNull(t('sdk.template.simple_listing.metaTitle', { title: section.title }));
  useSetPageNumber(pageInfo.pageNumber);
  const locale = t('locale');

  return (
    <Container>
      {data?.trends && (
        <Trends>
          {data?.trends?.data?.links?.data?.map((link: LinkComponentHref, key: number) => {
            return (
              <Tag className="mod-blue" key={key}>
                <NavLink href={link.url}>{link.title}</NavLink>
              </Tag>
            );
          })}
        </Trends>
      )}
      <Heros>
        {data.hero.heros.slice(0, 4).map((hero, index) => (
          <TertiaryHero key={index} {...hero} />
        ))}
      </Heros>
      <Ad className="Billboard_1  " desktopOnly noBackground megaban />
      <MainContent>
        <HeadingTwo className="mod-discarded-x2 mod-huge">{t('design.homepage.latestNewsTitle')}</HeadingTwo>
        <PostsList>
          {genericItems.map((genericItem: GenericItem, index: number) => {
            const link = {
              href: genericItem.url,
            };

            const isSponsoredItem = genericItem.tagSlugs?.includes('sponsored');

            const date =
              genericItem?.updatedAt > genericItem?.publishedAt ? genericItem?.updatedAt : genericItem?.publishedAt;

            if (index === 3 && data.hero.heros[4]) {
              const dateTimeAgo = displayDateDiff(data.hero.heros[4].updatedAt, locale);
              const linkHeroInFeed = {
                href: data.hero.heros[4].url,
              };

              return (
                <>
                  <Ad className="Mobile_Pos1" mobileOnly noBackground />
                  <ShowcaseItem
                    id={data.hero.heros[4].id}
                    key={data.hero.heros[4].id}
                    title={data.hero.heros[4].title}
                    image={{ imageId: data.hero.heros[4].image, alt: data.hero.heros[4].title }}
                    commentCount={data.hero.heros[4].comments}
                    authors={null}
                    date={dateTimeAgo}
                    link={linkHeroInFeed}
                    sponsoredItem={isSponsoredItem}
                  />
                  <PostItemLine
                    id={genericItem.id}
                    key={genericItem.id}
                    title={genericItem.title}
                    commentCount={genericItem.commentsNb}
                    image={{ imageId: genericItem.imageId, alt: genericItem.title }}
                    date={dateTimeAgo}
                    authors={genericItem.authors}
                    link={link}
                  />
                </>
              );
            }

            let currentInsert;
            if ((index + 1) % 8 === 0 && insertElements?.length > 0) {
              currentInsert = insertElements?.shift() || null;
              const dateTimeAgo = displayDateDiff(date, locale);
              if (currentInsert) {
                return (
                  <>
                    <Ad className={`Mobile_Pos${Math.round((index + 1) / 7) + 1}`} mobileOnly noBackground />
                    {currentInsert}
                    <PostItemLine
                      id={genericItem.id}
                      key={genericItem.id}
                      title={genericItem.title}
                      commentCount={genericItem.commentsNb}
                      image={{ imageId: genericItem.imageId, alt: genericItem.title }}
                      date={dateTimeAgo}
                      authors={genericItem.authors}
                      link={link}
                      sponsoredItem={isSponsoredItem}
                    />
                  </>
                );
              }
            }

            const dateTimeAgo = displayDateDiff(date, locale);

            return (
              <PostItemLine
                id={genericItem.id}
                key={genericItem.id}
                title={genericItem.title}
                commentCount={genericItem.commentsNb}
                image={{ imageId: genericItem.imageId, alt: genericItem.title }}
                date={dateTimeAgo}
                authors={genericItem.authors}
                link={link}
                sponsoredItem={isSponsoredItem}
              />
            );
          })}
        </PostsList>
        <div ref={detectorRef} aria-hidden />
      </MainContent>
      <SideContent ref={refSideBar}>
        <SideContentSpacer />
        <Ad className="HalfpageAd_1" desktopOnly />
        <SideContentSpacer />
        <Newsletter />
        <DesktopOnly>
          <SideContentSpacer />
          {data.topDownload?.data ? (
            <>
              <Title>{data.topDownload?.data?.info?.title}</Title>
              <ListItemRow data-testid="top-download">
                {data.topDownload?.data?.products?.productVariantList?.map((product: any) => {
                  const imageProps = {
                    imageId: product.imageId,
                    alt: product.title,
                  };

                  const LinkProps = {
                    href: product.urlCta ?? product.url,
                    text: t('sdk.template.download.download'),
                  };

                  return (
                    <ItemCtaSimple
                      small={false}
                      href={product.url}
                      key={product.id}
                      title={product.title}
                      image={imageProps}
                      cta={LinkProps}
                      subtitle={product.title}
                    />
                  );
                })}
              </ListItemRow>
            </>
          ) : null}
        </DesktopOnly>
        <SideContentBottom>
          <Ad className="HalfpageAd_2" desktopOnly />
        </SideContentBottom>
        <SideContentSpacer />
      </SideContent>
    </Container>
  );
};

export default HomePageClubic;
