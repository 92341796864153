import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useImageComponent, useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';
import { useTranslation } from '../utils/translation';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}

export interface PostItemProps {
  image: { imageId: string; alt: string };
  title: string;
  date: string;
  link: LinkProps;
  commentCount?: number;
  tag?: string;
  authors?: AuthorProp[];
  sponsoredItem?: boolean;
}

const Wrapper = styled.article`
  display: flex;
  margin-bottom: var(--spacer-m-fluid);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      border-radius: 5px;
      margin: 0 -15px var(--spacer);
    `,
  )}
`;

const Post = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  flex-direction: column;
  gap: var(--spacer-s);
  text-decoration: none;
`;

const ShowcaseImage = styled.div`
  picture {
    width: 100%;
  }

  img {
    object-fit: cover;
  }

  ${mq.lte(
    1024,
    css`
      height: 300px;
      width: 100vw;

      img {
        border-radius: 0;
      }

      picture {
        height: 100%;
      }
    `,
  )}

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      height: initial;
    `,
  )}
`;

const PublishDate = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      padding: 0 20px;
    `,
  )}
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.foreground};
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 26px;
  font-weight: 700;
  line-height: 1.38;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 20px;
      padding: 0 20px;
    `,
  )}
`;

const Informations = styled.div`
  align-items: center;
  color: ${(props) => (props.theme.isDark ? 'white' : props.theme.palette.blackAlt)};
  display: flex;
  flex-flow: wrap;
  font-size: 12px;
  gap: var(--spacer-xs);
  flex-direction: row;
  justify-content: flex-start;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 14px;
    `,
  )}
`;

const AuthorLabel = styled.span``;

const Comments = styled.span``;

const Sponsored = styled.span``;

export const ShowcaseItem: any = (props: PostItemProps) => {
  const { image, title, date, link, authors, commentCount, sponsoredItem } = props;

  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();
  const t = useTranslation();

  const authorString = authors && authors.length ? authors.map((author): string => author.title).join(', ') : null;

  const imageProps = {
    ...image,
    mobileWidth: 280,
    mobileHeight: 157,
    desktopWidth: 810,
    desktopHeight: 454,
  };

  return (
    <Wrapper>
      <LinkComponent {...link}>
        <Post>
          <ShowcaseImage>
            <ImageComponent {...imageProps} />
          </ShowcaseImage>
          <Title data-testid="meta-label">{title}</Title>
          <Informations>
            <PublishDate data-testid="meta-date">{date}</PublishDate>
            {authorString && (
              <AuthorLabel>{t('design.postItemLine.authorLabel', { author: authorString })}</AuthorLabel>
            )}
            {commentCount > 0 && (
              <Comments>
                ({commentCount} {t('design.postItemLine.commentLabel')}
                {commentCount > 1 ? 's' : ''}
                {sponsoredItem && ' - sponsorisé'})
              </Comments>
            )}
            {sponsoredItem && !commentCount && <Sponsored>(sponsorisé)</Sponsored>}
          </Informations>
        </Post>
      </LinkComponent>
    </Wrapper>
  );
};
