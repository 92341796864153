import React from 'react';
import styled, { css } from 'styled-components';
import { displayDateDiff } from '../atoms/date/utils';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useImageComponent, useLinkComponent } from '../utils/component';
import { useThemeSwitcher } from '../utils/theme-switcher';
import { useTranslation } from '../utils/translation';
import ArrowPlainDown from '../icons/arrowPlainDown';

export type SectionInsertItemProps = {
  title: string;
  url: string;
  imageId?: string;
  summary?: string;
  commentCount?: number;
  authors?: string[];
  publishedAt?: string;
  republishedAt?: string;
  updatedAt?: string;
  publishedAtString?: string;
  updatedAtString?: string;
};

export type SectionInsertProps = {
  title: string;
  link?: string;
  linkLabel?: string;
  backgroundImageUrl?: string;
  itemList: SectionInsertItemProps[];
};

const InsertContainer = styled.div`
  background-color: ${(props) => (props.theme.isDark ? props.theme.palette.darkBlack : '#F2F4F6')};
  display: flex;
  flex-direction: column;
  gap: var(--spacer-m-fluid);
  border-radius: 5px;
  padding: var(--spacer-l-to-s);
  margin-bottom: var(--spacer-l-fluid);
  width: 100%;
  position: relative;

  ${mq.gte(DEVICE_SIZE.MEDIUM, css``)}
`;

const InsertHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const InsertTitle = styled.h2`
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 20px;
  font-weight: bold;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      font-size: 34px;
    `,
  )}
`;

const InsertItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-m-fluid);
  justify-content: flex-start;
`;

const InsertItem = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-l-to-s);
  justify-content: flex-start;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      flex-direction: row;
    `,
  )}
`;

const InsertItemLeft = styled.div`
  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      width: 100%;

      img {
        object-fit: cover;
      }

      picture {
        width: 100%;
      }
    `,
  )}
`;

const InsertItemDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
  justify-content: space-between;
`;

const InsertItemDate = styled.div``;

const InsertItemTitle = styled.div`
  color: ${(props) => props.theme.colors.foreground};
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 20px;
  line-height: 1.38;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 26px;
    `,
  )}
`;

const InsertLink = styled.div`
  align-items: center;
  border-color: ${(props) => props.theme.colors.foreground};
  border-radius: 5px;
  border-style: solid;
  color: ${(props) => props.theme.colors.foreground};
  display: block;
  font-family: ${(props) => props.theme.typography.defaultFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  justify-content: space-around;
  transition: background 0.3s, color 0.3s;

  a {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: var(--spacer-s);
    padding: var(--spacer-s);
  }

  svg {
    position: relative;
    transform: rotate(-90deg);
    transition: left 0.3s;
    left: 0;
  }

  &:hover {
    svg {
      left: 5px;
    }
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 18px;
    `,
  )}
`;

const Informations = styled.div`
  align-items: center;
  color: ${(props) => (props.theme.isDark ? 'white' : props.theme.palette.blackAlt)};
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  font-size: 12px;
  gap: var(--spacer-xs);
  justify-content: flex-start;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 14px;
    `,
  )}
`;

const AuthorLabel = styled.span``;

const Comments = styled.span``;

export const SectionInsertBloc = ({
  title,
  link,
  linkLabel,
  itemList,
}: React.PropsWithChildren<SectionInsertProps>) => {
  const LinkComponent = useLinkComponent();
  const ImageComponent = useImageComponent();
  const t = useTranslation();
  const locale = t('locale');

  const { currentTheme } = useThemeSwitcher();

  const linkProps = {
    href: link,
  };

  return (
    <InsertContainer>
      {/* <InsertBackground style={{ backgroundImage: `url(${backgroundImageUrl})` }} /> */}
      <InsertHeader>
        <InsertTitle>{title}</InsertTitle>
      </InsertHeader>
      <InsertItemList>
        {itemList?.map((insertItem, key) => {
          const imageProps = {
            imageId: insertItem.imageId,
            alt: insertItem.title,
            mobileWidth: 240,
            mobileHeight: 157,
            desktopWidth: 240,
            desktopHeight: 135,
          };

          const insertLinkProp = {
            href: insertItem.url,
          };

          const authorString = insertItem?.authors?.join(', ') || null;
          const date =
            insertItem?.updatedAt > insertItem?.publishedAt ? insertItem?.updatedAt : insertItem?.publishedAt;
          const dateTimeAgo = displayDateDiff(date, locale);

          return (
            <LinkComponent key={key} {...insertLinkProp}>
              <InsertItem>
                <InsertItemLeft>
                  <ImageComponent {...imageProps} />
                </InsertItemLeft>
                <InsertItemDetails>
                  <InsertItemTitle>{insertItem.title}</InsertItemTitle>
                  <Informations>
                    <InsertItemDate>{dateTimeAgo}</InsertItemDate>
                    {authorString && (
                      <AuthorLabel>{t('design.postItemLine.authorLabel', { author: authorString })}</AuthorLabel>
                    )}
                    {insertItem.commentCount > 0 && (
                      <Comments>
                        ({insertItem.commentCount} {t('design.postItemLine.commentLabel')}
                        {insertItem.commentCount > 1 ? 's' : ''})
                      </Comments>
                    )}
                  </Informations>
                </InsertItemDetails>
              </InsertItem>
            </LinkComponent>
          );
        })}
      </InsertItemList>
      <InsertLink>
        <LinkComponent {...linkProps}>
          {linkLabel || t('design.sectionInsert.defaultViewMore', { sectionTitle: title })}
          <ArrowPlainDown width={20} height={20} color={currentTheme.colors.foreground} />
        </LinkComponent>
      </InsertLink>
    </InsertContainer>
  );
};
